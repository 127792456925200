import React from 'react';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import styles from "./Auction.module.scss";
import dynamic from "next/dynamic";
const BidsList = dynamic(() => import('../HotBid/Bidslist'))
const NotFoundSection = dynamic(() => import('../../NotFoundSection'))

function LiveAuctionNftList({all_nft_list, refreshMetadata}) {
    return (
        all_nft_list?.length > 0 ?
            <Row className={styles.nftRows}>
                {all_nft_list.map(info => {
                    return (
                        <Col xl={3} md={4} xs={6} key={info._id}>
                            <BidsList
                                id={info._id}
                                nftId={info.nft_id}
                                signature={info.signature}
                                put_on_sale={info.put_on_sale}
                                imgURL={info?.imageUrl?.length < 1 ? info.imageIPFS : info.imageUrl}
                                imageIPFS={info.imageIPFS}
                                is_image_loader={false}
                                walletAddress={info.walletAddress}
                                owner={info.owner}
                                title={info.itemName}
                                price={info.price}
                                collection_id={info.collection_id}
                                auctionType={info.auctionType}
                                tokenId={info.nft_id}
                                tokenQty={info.tokenQty}
                                closingTime={info.closingTime}
                                highestBidder={info.highestBidder}
                                currentBid={info.currentBid}
                                is_lazy_mint={info.is_lazy_mint}
                                royalty={info.royalty}
                                jsonIpfs={info.jsonIpfs}
                                user={info.user}
                                is_multiple={!!(info.is_multiple)}
                                ownerList={info.ownerList }
                                nftUsdPrice={info.nftUsdPrice ? info.nftUsdPrice : 0}
                                favoriteNFT={info.favoriteNFT ? info.favoriteNFT : {}}
                                chainIndex={info.chainIndex  ? info.chainIndex : 1}
                                refreshMetadata={refreshMetadata}
                            />
                        </Col>
                    )
                })}
            </Row>
            : <NotFoundSection type="no-auctions" />
    );
}

export default LiveAuctionNftList;